import one from "./1.png";
import two from "./2.png";
import three from "./3.png";
import four from "./4.png";
import five from "./5.png";
import six from "./6.png";
import seven from "./7.png";
import eight from "./8.png";
import nine from "./9.png";
import ten from "./10.png";
import eleven from "./11.png";
import twelve from "./12.png";
import thirteen from "./13.png";
import fourteen from "./14.png";
import fifteen from "./15.png";
import sixteen from "./16.png";
import seventeen from "./17.png";
import eighteen from "./18.png";
import nineteen from "./19.png";
import twenty from "./20.png";
import twentyone from "./21.png";
import twentytwo from "./22.png";
import twentythree from "./23.png";
import twentyfour from "./24.png";
import twentyfive from "./25.png";
import twentysix from "./26.png";
import twentyseven from "./27.png";

const altrideImages = [
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
  sixteen,
  seventeen,
  eighteen,
  nineteen,
  twenty,
  twentyone,
  twentytwo,
  twentythree,
  twentyfour,
  twentyfive,
  twentysix,
  twentyseven,
];

export default altrideImages;
